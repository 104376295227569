<script setup>
  import { useWebStore } from '@/store/web.js'
  import { useIntersectionObserver } from '~/composables/useIntersectionObserver'

  const { isIntersecting, elementRef } = useIntersectionObserver({ threshold: 0.5 })
  const store = useWebStore();
</script>
<template>
  <div class="w-11/12 mx-auto md:w-5/6 lg:w-3/4 2xl:w-3/5">
    <div>
      <text-with-line-after class="ml-3" :text="store.getLocaleString('au1')"/>
      <h3 ref="elementRef" class="text-4xl InterExtraBold uppercase tracking-tight mt-4">
        <ClientOnly>
            <Transition name="fade">
              <TypeWriter 
                v-if="isIntersecting" 
                :text="store.getLocaleString('au2')" 
                :speed="75"
              />
            </Transition>
          </ClientOnly>
        </h3>
    </div>
    <div>
      <p class="mt-12">
        <b class="text-xl lg:text-2xl">{{store.getLocaleString('au3')}}</b><br><br>
        <span class="hidden text-lg md:block lg:text-xl lg:mb-6">{{store.getLocaleString('au4')}}</span>
      </p>
    </div>
    <span v-if="['tabletL',''].includes(store.screenSize)" class="grid-element-span2-column">{{store.getLocaleString('au4')}}</span>
    <div id="aboutUsBanner">
      <ImageLazy src="/aboutUs_banner.webp" :alt="store.getLocaleString('imgAlt1')"/>
    </div>
  </div>
</template>
<script>
import TextWithLineAfter from "@/components/TextWithLineAfter.vue";

export default {
  name: "AboutUs",
  components: {TextWithLineAfter}
}
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
  }
  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }
</style>