<script setup>
  import CounterComp from "@/components/counter.vue";
  import MapMarker from "@/components/mapMarker.vue";
  import TextWithLineAfter from "@/components/TextWithLineAfter.vue";
  import { useWebStore } from '@/store/web.js'
  const store = useWebStore();
  const mapMarkers = reactive([
    {
      xCoordinate:266,
      yCoordinate:35,
      country: store.getLocaleString('ml3'),
      code:'ro'
    },
    {
      xCoordinate:10,
      yCoordinate:140,
      country: store.getLocaleString('ml4'),
      code:'al'
    },
    {
      xCoordinate:170,
      yCoordinate:10,
      country: store.getLocaleString('ml5'),
      code:'hu'
    },
    {
      xCoordinate:50,
      yCoordinate:-25,
      country: store.getLocaleString('ml6'),
      code:'be'
    },
    {
      xCoordinate:160,
      yCoordinate:40,
      country: store.getLocaleString('ml7'),
      code:'hr'
    },
    {
      xCoordinate:210,
      yCoordinate:60,
      country: store.getLocaleString('ml8'),
      code:'ma'
    },
    {
      xCoordinate:280,
      yCoordinate:15,
      country: store.getLocaleString('ml9'),
      code:'mo'
    },
    {
      xCoordinate:185,
      yCoordinate:30,
      country: store.getLocaleString('ml10'),
      code:'msrb'
    },
  ]);

</script>
<template>
<div class="bg-slate-950">
  <div class="w-11/12 mx-auto sm:w-3/4 xl:w-1/2">
    <TextWithLineAfter class="pt-4" :text="store.getLocaleString('ml1')" color="white" line-position="below"/>
    <div class="mt-4">
      <div >
        <counter-comp v-if="store.screenSize === 'mobile'"  class="count" size="50px"  maxValue="120"></counter-comp>
        <counter-comp v-else  class="count" maxValue="120"></counter-comp>
      </div>
      <p class="text-white text-xl">{{store.getLocaleString('ml2')}}</p>
    </div>
    <div id="map">
      <ImageLazy src="/map_background.webp" :alt="imgAlt4"/>
    </div>
  </div>
</div>
</template>
<style lang="scss" scoped>
  #mapLocationsOuter{
    overflow: hidden;
    padding-top: 2%;
    color: white;
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: rgb(21, 20, 28);

    #mapLocationsInner {
      margin: 0 auto;
      text-align: left;
      #locationsText {
        text-transform: uppercase;
        padding-bottom: 10px;
        border-bottom: 1.2px solid white;
      }
      #counterAndText{
        display: flex;
        flex-direction: row;
        margin-top: 2%;
        .counterContainer {
          width: 40%;
          .count {
            margin-left: 15%;
            width: min-content;
            position: relative;
          }
          .count::after {
            position: absolute;
            content: '+' !important;
            left: 100%;
            top: -10px;
            font-size: var(--largeTitleFont)
          }
          .counter{
            font-size: 100px;
          }
        }

        p{
          font-family: Inter-Medium,sans-serif;
          width: 50%;
          font-size: var(--largeTextFont)
        }


      }
      #map{
        margin-top: 23%;
        padding-left: 25%;
        text-align: right;
        position: relative;

        img {
          width: 100%;
          transform: scale(1.9);
        }
      }
    }
    &.mobile,
    &.tablet{
      height: unset;
      padding-bottom: 280px;
      #mapLocationsInner {
        max-width: 85%;
        #counterAndText .counterContainer .count::after{
            font-size: 30px !important;
            top: 10px;
          }
        #counterAndText{
          position: relative;
          z-index: 10;
          p{
            margin-left: 60px;
            width: 100%;
          }
        }
        #map{
          z-index: 0;
          background-size: contain;
          img{
            transform: scale(7) translateX(32%) translateY(20%);
            z-index: 0;
          }
        }
      }
    }
  }
</style>
