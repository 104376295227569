<script setup>
import { useWebStore } from '@/store/web.js'

  const store = useWebStore();

  const branches = reactive([
    {
      title: store.locale.current.hb1,
      image: 'blowingMachine',
      url: store.sites[3].url,
      fact_title: store.locale.current.hb2
    },
    {
      title: store.locale.current.hb3,
      image: 'flakongyartas',
      url: store.sites[1].url,
      fact_title: store.locale.current.hb4,
    },
    {
      title: store.locale.current.hb5,
      image: 'szerelekgyartas',
      url: store.sites[2].url,
      fact_title: store.locale.current.hb6,
    },
    ]
  )

  function scrollToComponent(componentId) {
    var element = document.getElementById(componentId);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth', // Smooth scrolling
        block: 'center' // Scroll to the middle of the element
      })
      // Add a class to highlight the element
      element.classList.add('border-color-orange')
      // Remove the class after 3 seconds
      setTimeout(function() {
        element.classList.remove('border-color-orange');
      }, 3000);
    } else {
      console.warn('Element with ID ' + componentId + ' not found.');
    }
  }
  store.chosenTab = store.sites[0].url;

</script>

<template>
  <div class="overflow-scroll">
    <div class="relative  my-8  flex w-5/6 h-min mx-auto">
      <div class="text-left z-10 leading-relaxed">
        <span class="flex">
            <img
              class="absolute my-auto -right-full max-w-xl opacity-50 h-screen/80 sm:relative sm:right-0 sm:opacity-100 sm:my-0 sm:h-screen/40 md:my-auto"
              src="/i_betu_spottal.webp"
              :alt="store.getLocaleString('imgAlt0')"
              />
            <span class="xl:grid xl:grid-cols-2">
              <span >
                <h1 class="text-4xl InterExtraBold leading-snug w-fit lg:text-5xl">
                  {{store.getLocaleString('h1')}}<br> <span class="text-orange-500">{{store.getLocaleString('h2')}}</span>{{store.getLocaleString('h3')}}
                </h1>
                <h2 class="hidden mt-12 text-3xl xl:block">
                  {{store.getLocaleString('h4')}}
                </h2>
            </span>
              <div class="hidden md:flex align-items-start flex-1">
                <div class="grid grid-cols-2 gap-20 w-full mt-12 xl:my-auto">
                  <counter class="InterExtraBold text-orange-500" :color="store.screenSize === 'mobile'? 'black':''" :text="store.getLocaleString('h5')" maxValue="120"></counter>
                  <counter class="InterExtraBold text-orange-500" :color="store.screenSize === 'mobile'? 'black':''" :text="store.getLocaleString('h6')" maxValue="30"></counter>
                  <counter class="InterExtraBold text-orange-500" :color="store.screenSize === 'mobile'? 'black':''" :text="store.getLocaleString('h7')" maxValue="110"></counter>
                  <counter class="InterExtraBold text-orange-500" :color="store.screenSize === 'mobile'? 'black':''" :text="store.getLocaleString('h8')" maxValue="85"></counter>
                </div>
              </div>
            </span>
          </span>
            <h2 class="mt-12 text-2xl font-medium w-3/4 sm:w-full sm:mt-6 md:text-center lg:text-3xl xl:hidden">
              {{store.getLocaleString('h4')}}
            </h2>
        </div>
      </div>

    <div class="grid gap-8 w-full mx-auto px-8 sm:grid-cols-2 xl:grid-cols-3" >
      <branch-card v-for="branch in branches" v-bind:key="branch.title" :title="branch.title" :url="branch.url" :image="branch.image" :fact_title="branch.fact_title" :fact_text="branch.fact_text" :subtitle_color="branch.subtitle_color" @click="$store.dispatch('navigateTo',branch.url)" :after_text="store.locale.current.hb7"></branch-card>
    </div>
    <enquiry-button class="mb-10 mt-10 " @click="scrollToComponent('enquiry')"/>
    <about-us></about-us>
    <div class="animatedText mt-7 w-full whitespace-nowrap text-3xl SpaceGrotesk font-medium"><p class="text-orange-500 tracking-tight">{{store.getLocaleString('at')}}</p></div>
    <!--<MapLocations/>-->
    <wire-frame-design/>
    <photo-montage :photos="[{src:'excavator_pic.webp',alt:store.getLocaleString('imgAlt5')},{src: 'hegesztes.webp',alt:store.getLocaleString('imgAlt6')}, {src:'banyaKanal.webp',alt:store.getLocaleString('imgAlt7')}, {src: 'robothegesztes.webp',alt:store.getLocaleString('imgAlt8')}, ]"/>
    <CustomCarousel class="mt-9" :slides="['knorr-bremse-logo-black-and-white.webp','zf_logo.webp','grundfos-logo-black-and-white.webp','bpw_logo.webp','schaeffler_logo.webp','tt.webp','ssab-logotype-black-rgb.webp']"/>

    <h3 class="InterExtraBold text-3xl uppercase mt-12 lg:text-4xl lg:mb-16">{{store.getLocaleString('h11')}}</h3>
    <blowing-machine-wire-frame-design/>
    <div class="w-full whitespace-nowrap"/>
    <MapLocations/>
    <photo-montage-portrait v-if="store.screenSize !== 'mobile' " class="sm:block"/>

    <h3 class="InterExtraBold text-3xl uppercase mt-20 lg:text-4xl lg:mb-12">{{store.getLocaleString('h18')}}</h3>

    <BottleProductionMain/>

    <div class="animatedText mt-7 w-full whitespace-nowrap text-3xl SpaceGrotesk font-medium"><p class="text-orange-500 tracking-tight">{{store.getLocaleString('at')}}</p></div>
    <photo-montage :photos="[{src:'feher_flakonok.webp',alt:store.getLocaleString('imgAlt9')}, {src:'szerszamgyartas.webp',alt:store.getLocaleString('imgAlt10')}, {src: 'flakon_tervezes.webp',alt:store.getLocaleString('imgAlt11')},{src: 'plastic_bottle_city.webp',alt:store.getLocaleString('imgAlt12')} ]"/>
    <ContactUsPage title="Keressen minket!"
                   subtitle="Forduljon hozzánk bizalommal"
                   contact-phone="06 20 485 0585"
                   contact-email="lukacs.csaba@poliol.hu"
                   contact-topic="general"
                   class="mt-12" />
    <CustomCarousel :bordered="false" :slides="['medikemia-logo.webp','szentkiralyi_logo.webp','fantana-mare_logo.webp','resilux_logo.webp','royalsekt_logo.webp','florin_logo.webp','caola_logo.webp']"/>
    <FooterComp/>
  </div>
</template>
<style lang="scss">

  //ANIMACIOK
  :root {
    --newNum: 4;
    --duration1: 2s;
    --duration2: calc(var(--duration1) / 2);
    --delay1: calc(var(--duration2) / 2);
    --delay2: calc(var(--duration2) + var(--delay1));
    --easing: cubic-bezier(0.65, 0.05, 0.36, 1);
  }
  .count {
    font-family: Inter-ExtraBold,sans-serif;
    opacity: 0;
    color: var(--orange-color);
    animation: appearSmoothly .5s var(--easing) forwards;
  }
  .animatedText{
    p{
      animation: move 120s linear infinite;
    }
  }
  .mobile .animatedText{
    p{
      animation: move 23s linear infinite;
    }
  }


  @keyframes appearSmoothly {
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }

  @keyframes pulse-orange {
    0% {
      //transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
    }

    70% {
      //transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
    }

    100% {
      //transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
    }
  }

  @keyframes move {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
  }


</style>