<script setup>
import { useWebStore } from '@/store/web.js'
const store = useWebStore();
</script>

<template>
  <NuxtLink :to="'/' + store.chosenLang + '/' + url" class="block">
    <div class="relative overflow-hidden deep-shadow hover:cursor-pointer group">
      <img :src="'/'+image+'.webp'" class="w-full transition-transform duration-700 ease-in-out group-hover:scale-105" :alt="title"/>
      <div :class="[
        'absolute bottom-0 left-0 w-full p-4 bg-white bg-opacity-60 backdrop-blur-sm transition-all duration-300 ease-in-out',
        'transform group-hover:translate-y-0'
      ]">
        <h5 class="font-['Inter-ExtraBold'] text-left text-xl mb-2 leading-tight lg:text-2xl">{{ title }}</h5>
        <h6 class="hidden  font-['Inter-Medium'] text-sm md:text-base mb-4 opacity-80 lg:block lg:text-lg lg:text-left">{{ fact_title }}</h6>
        <div class="text-left InterMedium">
          {{ after_text || 'Olvass tovább' }}
        </div>
      </div>
    </div>
  </NuxtLink>
</template>

<script>
export default {
  name: 'branchCard',
  props: ['title', 'url', 'fact_title', 'fact_text', 'subtitle_color', 'image', 'after_text'],
}
</script>

<style scoped>
.shadow-lg {
  box-shadow: 
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: box-shadow 0.3s ease-in-out;
}

.shadow-lg:hover {
  box-shadow: 
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.read-more-btn:hover {
  transform: translateX(5px);
}
</style>
