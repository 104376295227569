<script setup>
import MapMarker from "@/components/mapMarker.vue";
import ImageLazy from "@/components/ImageLazy.vue"; // Assuming you have this component
import { useWebStore } from '@/store/web.js'

const store = useWebStore();
const mapMarkers = [
  {
    xCoordinate: 1087,
    yCoordinate: 330,
    country: 'Robosztus váz',
    code: 'bm0'
  },
  {
    xCoordinate: 630,
    yCoordinate: 380,
    country: 'Szilárd záró szerszám',
    code: 'bm1'
  },
  {
    xCoordinate: 700,
    yCoordinate: 250,
    country: 'Szervo motorokkal vezérelt flakongyártás',
    code: 'bm2'
  },
  {
    xCoordinate: 700,
    yCoordinate: 410,
    country: '40 Bar-ig növelhető pneumatikai rendszer',
    code: 'bm3'
  },
  {
    xCoordinate: 700,
    yCoordinate: 410,
    country: 'Levegővisszanyerő rendszer',
    code: 'bm4'
  },
  {
    xCoordinate: 700,
    yCoordinate: 410,
    country: 'Gyors átállási lehetőség',
    code: 'bm5'
  },
]
</script>

<template>
  <div class="w-full pt-20 md:pt-0">
    <div class="w-11/12 mx-auto sm:w-3/4 lg:w-5/6 xl:w-3/4 2xl:w-3/5">
      <div class="flex gap-7 flex-col lg:flex-row">
        <h3 class="hidden InterExtraBold text-4xl uppercase text-orange-500 md:w-1/2 lg:block 2xl:text-right">
          {{ store.getLocaleString('bmwfd2') }}
        </h3>
        <p class="mt-8 text-xl leading-[30px] md:mt-2 lg:w-1/2 lg:text-justify">
          {{ store.getLocaleString('bmwfd3') }}
        </p>
      </div>
      <div class="relative mt-8">
        <ImageLazy class="w-full sm:w-3/4 mx-auto my-3" src="/fuvogep_wireframe.webp" :alt="store.getLocaleString('imgAlt2')"/>
        <!--<MapMarker 
          v-for="marker in mapMarkers" 
          :key="marker.code" 
          :markerData="marker" 
          color="black" 
          class="hidden md:block" 
          popupBackground="white"
        />-->
      </div>
    </div>
  </div>
</template>
